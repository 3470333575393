<template>
  <div>
    <b-card no-body class="mb-2 p-5">
      <h3 class="mb-2">{{ $t('Upload Skip') }}</h3>
      <validation-observer ref="uploadForm">
        <b-alert show class="p-2" variant="warning">
          Tải file mẫu <a :href="templateUrl" target="_blank">tại đây</a>.
        </b-alert>
        <form @submit.prevent="uploadFile">
          <b-row align-v="end">
            <b-col>
              <validation-provider name="file" mode="eager">
                <b-form-file id="fileUpload" v-model="file" drop-placeholder="Drop file here..." />
              </validation-provider>
            </b-col>
            <b-col>
              <base-select
                id="header-row-pos"
                v-model="partnerSelected"
                :reduce="(item) => item.value"
                :options="partners"
                :clearable="false"
                label="text"
                rules="required"
                placeholder="Chọn đối tác"
              />
            </b-col>
            <b-col cols="2">
              <b-button variant="primary" type="submit" :disabled="loading">
                <b-spinner v-if="loading" small />
                <span class="align-middle ml-50">Upload data</span>
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
      <div class="py-3">
        <b-alert
          v-for="(err, indx) in errors"
          :key="indx"
          class="p-1"
          show
          variant="danger"
          dismissible
        >
          {{ err[0] }}
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BAlert, BButton, BCard, BCol, BFormFile, BRow, BSpinner } from 'bootstrap-vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { partnerList } from '@/apis/apiPartner';
import { uploadSkips } from '@/apis/apiImport';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import { required } from '@validations';

export default {
  components: {
    BAlert,
    BaseSelect,
    BButton,
    BCard,
    BCol,
    BFormFile,
    BRow,
    BSpinner,
  },
  data: () => ({
    required,
    file: null,
    partners: [],
    partnerSelected: null,
    loading: false,
    errors: [],
  }),
  computed: {
    templateUrl() {
      return `${new URL(process.env.VUE_APP_URL).origin}/template/template-upload-skip.xlsx`;
    },
  },
  created() {
    this.getPartnersAPI();
  },
  methods: {
    uploadFile() {
      this.loading = true;
      uploadSkips(this.partnerSelected, this.file)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Upload {resource} success', {
                resource: this.$t('Skip'),
              }),
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: constRouter.IMPORT.name,
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Upload {resource} failed', {
                resource: this.$t('Skip'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          if (err.response.status === 422) {
            this.errors = err.response?.data?.errors || [err.response?.data?.message];
            console.log(this.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getPartnersAPI() {
      partnerList(this.options)
        .then((res) => {
          this.partners = res.data.data.map((partner) => ({
            value: partner.id,
            text: partner.name,
          }));
        })
        .catch(() => {});
    },
  },
};
</script>
